import '/src/scss/style.scss';

if (import.meta.hot) {
	import.meta.hot.accept();
}

import 'lazysizes';

import '@/plugins/logger';

// import webfontloader from '@/plugins/webfontloader';
import detectTab from '@/utils/detectTab';
import activeLinks from '@/utils/activeLinks';
import localLinks from '@/utils/localLinks';

// webfontloader();

// Loop through light gallery groups and initilize lightgallery
var lightGalleries = document.getElementsByClassName('lightgallery');
Array.prototype.forEach.call(lightGalleries, function (gallery) {
	lightGallery(gallery, {
		selector: '.lightgallery--trigger',
		download: false,
		getCaptionFromTitleOrAlt: true,
	});
});

detectTab();
activeLinks(window.location.pathname);
localLinks();
